import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import BestSellers from 'src/components/OurProducts/BestSellers'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import BestSellersBg from '../../assets/img/categories/bestsllers.png';

const SEO_CONFIG = {
  title: 'Best Sellers | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Best Sellers']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Best Sellers" image={BestSellersBg}>
          <BestSellers />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
